import React from "react";

export enum ScreenType {
  HOMEPAGE = "homepage",
  PROFILE = "profile",
  AQ_JOURNEY = "aq_journey",
  PEOPLE = "people",
  MY_REPORT = "my_report",
  ALL_REPORT = "all_report",
  COMMUNITY = "community",
  AQ_TEAM = "aqTeam",
  AQ_TEAM_ONE_PAGER = "aqTeam-one-pager",
  ORG_MANAGE_PAGE = "org_manage",
  AQ_ME = "aqMe",
  AQ_ME_ONE_PAGER = "aqme-one-pager",
  AQ_ME_ADVANCED = "aqMe_advanced_insights",
  ADMIN_HOMEPAGE = "admin_homepage",
  ADMIN_PUSH_NOTIFICATIONS = "admin_push_notifications",
  ADMIN_PLATFORM_CONTENT = "admin_platform_content",
  ADMIN_USERS = "admin_users",
  ADMIN_ORGANIZATIONS = "admin_organizations",
  ADMIN_INVITATIONS = "admin_invitations",
  ADMIN_REPORT_CONTENT = "admin_report_content",
  ADMIN_AQME_REPORT = "admin_aqme_report",
  ADMIN_AQTEAM_REPORT = "admin_aqteam_report",
  ADMIN_ADAVANCE_INSIGHT_REPORT = "admin_advance_insight_report",
  ADMIN_COUPONS = "admin_coupons",
  ADMIN_PRODUCT_UPDATES = "admin_product_updates",
  ADMIN_META_INFORMATION = "admin_admin_information",
  ADMIN_REPORTS = "admin_reports",
  ADMIN_DATA_EXTRACTS = "admin_data_extracts",
  ADMIN_AQJOURNEY_TASKS = "admin_aqjourney_tasks",
  ADMIN_AQJOURNEY_MILESTONES = "admin_aqjourney_milestones",

  ADMIN_ACCOUNT = "admin_account",

  ADMIN_SETTINGS = "admin_settings",
  ADMIN_MANAGE_OTP = "admin_manage_otp",
  DATA_EXPORT = "data_export",
  NOTIFICATION_SETTINGS = "notification_settings",
  UPDATE_PASSWORD = "update_password",
}

export const screenTitleAndDescription: {
  [key: string]: { title: React.ReactElement; description: string };
} = {
  [ScreenType.HOMEPAGE]: {
    title: <></>,
    description:
      "This is your 'Platform HQ', your dashboard where you can manage your credits, view your AQme report, and access all of our platform features",
  },
  [ScreenType.AQ_TEAM]: {
    title: <></>,
    description: "Access to the AQteam report and review team's AQ scores",
  },
  [ScreenType.AQ_TEAM_ONE_PAGER]: {
    title: <></>,
    description:
      "Access to AQteam summary, a two pager report displaying the team scores",
  },
  [ScreenType.AQ_JOURNEY]: {
    title: (
      <>
        {" "}
        My <span className={"text-brandYellow2"}>AQ</span> Journey
      </>
    ),
    description: "Track your progress with us, from newbie to AQ master",
  },
  [ScreenType.PEOPLE]: {
    title: <>Manage people</>,
    description:
      "Set up, manage and see all the people in your teams and organizations. View their status and perform admin tasks",
  },
  [ScreenType.DATA_EXPORT]: {
    title: <>Data Export</>,
    description: "",
  },
  [ScreenType.MY_REPORT]: {
    title: <></>,
    description:
      "This is your AQme report which shows you your adaptability scores, profile, and metrics",
  },
  [ScreenType.COMMUNITY]: {
    title: <>Events and Community</>,
    description:
      "Access the AQ community, participate in events and view content created by the community",
  },
  [ScreenType.PROFILE]: {
    title: <>Manage profile</>,
    description:
      "Set up your profile - add your photo, details and information. Making sure this is up to date will ensure you get the most from our platform",
  },
  [ScreenType.NOTIFICATION_SETTINGS]: {
    title: <>Manage settings</>,
    description:
      "Set up your notification settings - choose how you want to be notified and when",
  },
  [ScreenType.ALL_REPORT]: {
    title: <>AQ Report Centre</>,
    description:
      "All about the data - quick access to common reports, templates and downloads",
  },
  [ScreenType.ORG_MANAGE_PAGE]: {
    title: <>Manage Organization</>,
    description:
      "Set up, manage and edit the information of your clients and organizations. It is important to get this right to help with the easy invitations and reporting of each of your clients across the platform",
  },
  [ScreenType.AQ_ME]: {
    title: <></>,
    description:
      "Access to the AQme report dashboard, with all results and profile information",
  },
  [ScreenType.AQ_ME_ONE_PAGER]: {
    title: <></>,
    description:
      "Access to the AQme Summary, a one pager report displaying snapshot of user's scores",
  },
  [ScreenType.AQ_ME_ADVANCED]: {
    title: <></>,
    description:
      "Access an exclusive coach only report to get advanced insights about your clients",
  },
  [ScreenType.ADMIN_HOMEPAGE]: {
    title: <></>,
    description: "This is your Admin Panel",
  },
  [ScreenType.ADMIN_PUSH_NOTIFICATIONS]: {
    title: <>Push Notifications</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_PLATFORM_CONTENT]: {
    title: <>Platform Content</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_USERS]: {
    title: <>Manage users</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_ORGANIZATIONS]: {
    title: <>Manage organizations</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_INVITATIONS]: {
    title: <>Manage invitations</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_REPORT_CONTENT]: {
    title: <>Report content</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_AQME_REPORT]: {
    title: <>AQme report</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_AQTEAM_REPORT]: {
    title: <>AQTeam report</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_ADAVANCE_INSIGHT_REPORT]: {
    title: <>Advance Insights report</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_COUPONS]: {
    title: <>Manage coupons</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_PRODUCT_UPDATES]: {
    title: <>Product Updates</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_META_INFORMATION]: {
    title: <>Manage meta data</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_REPORTS]: {
    title: <>Reports</>,
    description: "Description of this section",
  },

  [ScreenType.ADMIN_DATA_EXTRACTS]: {
    title: <>Data export</>,
    description: "Description of this section",
  },

  [ScreenType.ADMIN_AQJOURNEY_TASKS]: {
    title: <>My AQ Journey Tasks</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_AQJOURNEY_MILESTONES]: {
    title: <>My AQ Journey Milestones</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_ACCOUNT]: {
    title: <>Account</>,
    description: "Description of this section",
  },
  [ScreenType.ADMIN_SETTINGS]: {
    title: <>Settings</>,
    description: "Description of this section",
  },
  [ScreenType.UPDATE_PASSWORD]: {
    title: <>Update temporary password</>,
    description: "Update temporary password to users ",
  },
  [ScreenType.ADMIN_MANAGE_OTP]: {
    title: <>Manage Email Verification OTP</>,
    description: "Here, you can manage the email verification OTPs.",
  },
};
