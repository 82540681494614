import { EnterpriseReport } from "../models/EmterpriseReportModel";
import { Survey } from "../models/Survey";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";

class ReportService {
  static getInstance(): ReportService {
    return new ReportService();
  }

  getIndividualReport() {
    return apiService
      .get<{ data: { [key: string]: number } }>("/me/reports", true, true)
      .then((report: any) => report.data);
  }

  getEnterpriseReport(filters: { [key: string]: any }) {
    // TODO:: This catch block must be inside ApiService, move it after fixing login, register components
    return apiService
      .get<{ data: { [key: string]: EnterpriseReport } }>(
        "/reports/enterprise",
        true,
        true,
        filters,
      )
      .then((report: any) => report);
  }

  addReportUrl(surveyId: number, data: { url: string }): Promise<Survey> {
    return apiService
      .post<ShowResponse<Survey>>(`/survey/${surveyId}/url`, true, false, data)
      .then((response) => response.data);
  }

  getPresignedUrl(): Promise<{ url: string }> {
    return apiService
      .get<ShowResponse<{ url: string }>>(`/presigned-url`, true, false)
      .then((res) => res.data);
  }

  async fetchReassementsCounts(
    team_ids?: number[],
    org_ids?: number[],
  ): Promise<{ reassessmentsCounts: number[] }> {
    return apiService
      .get<ShowResponse<{ reassessmentsCounts: number[] }>>(
        `/maximum-aqme-surveys-completed-count`,
        true,
        true,
        { team_ids: team_ids, org_ids: org_ids },
      )
      .then((res) => res.data);
  }
}

export const reportService = ReportService.getInstance();
