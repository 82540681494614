import { SvgIcon } from "@material-ui/core";
import bulb from "./svg-icons/bulb.svg";
import allReportIcon from "./svg-icons/allReportsIcon.svg";
import communityIcon from "./svg-icons/communityIcon.svg";
import helpAndSupportIcon from "./svg-icons/helpAndSupportIcon.svg";
import peopleIcon from "./svg-icons/peopleIcon.svg";
import profileIcon from "./svg-icons/profileIcon.svg";
import settingIcon from "./svg-icons/settingIcon.svg";
import rocket from "./svg-icons/rocket.svg";
import taskIcon from "./svg-icons/taskIcon.svg";
import roundedPlusIcon from "./svg-icons/roundedPlusIcon.svg";
import rightDoubleArrowRounded from "./svg-icons/rightDoubleArrowRounded.svg";
import socialShare from "./svg-icons/socialShare.svg";
import viewIcon from "./svg-icons/viewIcon.svg";
import cupActiveIcon from "./svg-icons/cupActiveIcon.svg";
import cupIcon from "./svg-icons/cupIcon.svg";
import homeIcon from "./svg-icons/homeIcon.svg";
import homeActiveIcon from "./svg-icons/homeActiveIcon.svg";
import reportCenter from "./svg-icons/reportCenter.svg";
import reportCenterActive from "./svg-icons/reportCenterActive.svg";
import crossIcon from "./svg-icons/crossIcon.svg";
import crossIconWhite from "./svg-icons/crossIconWhite.svg";
import searchIcon from "./svg-icons/searchIcon.svg";
import downloadIcon from "./svg-icons/downloadIcon.svg";
import upDownFilter from "./svg-icons/upDownFilter.svg";
import userIcon from "./svg-icons/userIcon.svg";
import userActiveIcon from "./svg-icons/userActiveIcon.svg";
import pencilIcon from "./svg-icons/pencilIcon.svg";
import uploadIcon from "./svg-icons/uploadIcon.svg";
import communityIconNavBar from "./svg-icons/communityIconNavbar.svg";
import communityIconActiveNavBar from "./svg-icons/communityIconActiveNavbar.svg";
import rocketWithStars from "./svg-icons/rocketWithStars.svg";
import downIcon from "./svg-icons/downIcon.svg";
import upIcon from "./svg-icons/upIcon.svg";
import plusIcon from "./svg-icons/plusIcon.svg";
import menuDotIcon from "./svg-icons/menuDotIcon.svg";
import myReportIcon from "./svg-icons/myReportIcon.svg";
import myReportIconActive from "./svg-icons/myReportIconActive.svg";
import peopleIconNavbar from "./svg-icons/peopleIconNavBar.svg";
import peopleIconActive from "./svg-icons/peopleIconActive.svg";
import downwardArrow from "./svg-icons/downwardArrow.svg";
import downwardArrowWhite from "./svg-icons/downwardArrowWhite.svg";
import logoutIcon from "./svg-icons/logoutIcon.svg";

import helpSupportNavbar from "./svg-icons/helpSupportIconNavBar.svg";
import teamIconGray from "./svg-icons/teamIconGray.svg";
import peopleIconGray from "./svg-icons/peopleIconGray.svg";
import negativeCircleBlue from "./svg-icons/negativeCircleBlue.svg";
import plusCircleBlue from "./svg-icons/plusCircleBlue.svg";
import zoomReset from "./svg-icons/zoomReset.svg";
import plusIconWhite from "./svg-icons/plusIconWhite.svg";
import nodeIcon from "./svg-icons/nodeIcon.svg";
import orgTeamIcon from "./svg-icons/orgTeamIcon.svg";
import orgTeamIconActive from "./svg-icons/orgTeamIconActive.svg";

import upwardArrow from "./svg-icons/upwardArrow.svg";
import documents from "./svg-icons/documents.svg";
import duplicate from "./svg-icons/duplicate.svg";
import deleteIcon from "./svg-icons/deleteIcon.svg";
import roundedFullPlusIcon from "./svg-icons/roundedFullPlusIcon.svg";

import upwardArrowWhite from "./svg-icons/upwardArrowWhite.svg";
import documentsIconWhite from "./svg-icons/documentsIconWhite.svg";
import duplicateIconWhite from "./svg-icons/duplicateIconWhite.svg";
import eyeSolid from "./svg-icons/eyeSolid.svg";
import eyeSlashSolid from "./svg-icons/eyeSlashSolid.svg";
import minusIcon from "./svg-icons/minusIcon.svg";
import roundedBg from "./svg-icons/roundedBg.svg";
import playIcon from "./svg-icons/play.svg";
import stackIcon from "./svg-icons/stackIcon.svg";
import podcastIcon from "./svg-icons/podcastIcon.svg";
import referIcon from "./svg-icons/referIcon.svg";
import specksIcon from "./svg-icons/specksIcon.svg";
import reportCenterIconOrange from "./svg-icons/reportCenterIconOrange.svg";
import sendIcon from "./svg-icons/sendIcon.svg";
import downloadIconWhite from "./svg-icons/downloadIconWhite.svg";
import downloadIcon2White from "./svg-icons/downloadIcon2White.svg";
import socialShareWhite from "./svg-icons/socialShareWhite.svg";
import feedBackIcon from "./svg-icons/feedBackIcon.svg";
import abilityDialIcon from "./svg-icons/ability-dial-icon-2.svg";
import environmentDialIcon from "./svg-icons/environment-dial-icon-2.svg";
import characterDialIcon from "./svg-icons/characterDialIcon.svg";
import characterDialIcon2 from "./svg-icons/characterDialIcon2.svg";
import downwardIndicator from "./svg-icons/downwardIndicator.svg";
import upwardIndicator from "./svg-icons/upwardIndicator.svg";
import abilityDashedLine from "./svg-icons/abilityDashedLine.svg";
import environmentDashedLine from "./svg-icons/environmentDashedLine.svg";
import environmentFlag from "./svg-icons/environmentFlag.svg";
import inActiveFlag from "./svg-icons/no-color-flag.svg";
import abilityFlag from "./svg-icons/ability-flag.svg";
import characterInactiveDialIcon from "./svg-icons/characterInactiveDialIcon.svg";
import abilityTipFlag from "./svg-icons/abilityTipFlag.svg";
import environmentTipFlag from "./svg-icons/environmentTipFlag.svg";
import greyIconUp from "./svg-icons/grey-icon-up.svg";
import greyIconDown from "./svg-icons/grey-down-icon.svg";
import abilityNavIcon from "./svg-icons/abilityNavIcon.svg";
import overviewNavIcon from "./svg-icons/overviewNavIcon.svg";
import characterNavIcon from "./svg-icons/characterNavIcon.svg";
import devPlanNavIcon from "./svg-icons/devPlanNavIcon.svg";
import environmentNavIcon from "./svg-icons/environmentNavIcon.svg";
import questionMarkIcon from "./svg-icons/questionMarkIcon.svg";
import blueUpIcon from "./svg-icons/blueUpIcon.svg";
import blueDownIcon from "./svg-icons/blueDownIcon.svg";
import clockIcon from "./svg-icons/clockIcon.svg";
import calenderIcon from "./svg-icons/calenderIcon.svg";
import shareReportSvgIcon from "./svg-icons/share-social-svg.svg";
import shareReportSvgIconDark from "./svg-icons/share-social-dark-svg.svg";
import downloadreportSvgIcon from "./svg-icons/download-svg-icon.svg";
import downloadReportSvgIconWhite from "./svg-icons/download-svg-icon-white.svg";
import aqLogoSvg from "./svg-icons/aqLogo.svg";
import aiPredicitiveDashedLine from "./svg-icons/ai-predictive-dashed-line.svg";
import genderGoldenIcon from "./svg-icons/male-female-golden.svg";
import gitMergeGoldenIcon from "./svg-icons/git-merge-golden.svg";
import infoCircleGoldenIcon from "./svg-icons/information-circle-golden.svg";
import stopWatchGoldenIcon from "./svg-icons/stopwatch-golden.svg";
import bussinessGoldenIcon from "./svg-icons/business-golden.svg";
import documentGolden from "./svg-icons/documents-golden.svg";
import briefcaseGoldenIcon from "./svg-icons/briefcase-golden.svg";
import discGoldenIcon from "./svg-icons/disc-golden.svg";
import calendarGoldenIcon from "./svg-icons/calendar-golden.svg";
import warningIcon from "./svg-icons/warningIcon.svg";
import constructIconLight from "./svg-icons/construct-icon-light.svg";
import sparkleIconLight from "./svg-icons/sparkles-icon-light.svg";
import characterDashedLine from "./svg-icons/character-dashed-line.svg";
import smallAbilityActiveFlag from "./svg-icons/small-ability-active-flag.svg";
import smallCharacterActiveFlag from "./svg-icons/small-character-active-flag.svg";
import smallInactiveFlag from "./svg-icons/small-inactive-flag.svg";
import smallAqOptamizationFlag from "./svg-icons/Ai-optamization-active-flag.svg";
import lowBandFlag from "./svg-icons/low-band-flag.svg";
import midBandFlag from "./svg-icons/mid-band-flag.svg";
import highBandFlag from "./svg-icons/high-band-flag.svg";
import inActiveBandFlag from "./svg-icons/inactive-band-flag.svg";
import environmentSmallFlag from "./svg-icons/environment-small-flag.svg";
import userProfileIcon from "./svg-icons/user-profile-icon.svg";
import eyeIcon from "./svg-icons/eye-icon.svg";
import eyeIconWithSlash from "./svg-icons/eye-icon-with-slash.svg";
import envleopeIcon from "./svg-icons/envelope-icon.svg";
import sliderFilterIcon from "./svg-icons/sliders_filter_icon.svg";
import participantsRoundedLogo from "./svg-icons/participants-rounded-logo.svg";
import googleLogo from "./svg-icons/google-logo.svg";
import greenTickMark from "./svg-icons/green-tick-mark.svg";
import redCrossIcon from "./svg-icons/red-cross-mark.svg";
import linkedInIcon from "./svg-icons/linkedIn.svg";
import mailIcon from "./svg-icons/mail-icon.svg";
import retryIcon from "./svg-icons/retry-icon.svg";
import deleteIconWhite from "./svg-icons/deleteIconWhite.svg";
import editingIcon from "./svg-icons/editing.svg";
import bellOffIcon from "./svg-icons/bell-off.svg";
import bellOffDarkIcon from "./svg-icons/bell-off-dark.svg";
import bellIcon from "./svg-icons/bell.svg";
import settingsClassicIcon from "./svg-icons/settings-classic.svg";
import bellGreen from "./svg-icons/bell-green.svg";
import circleHelp from "./svg-icons/circle-help.svg";

export enum SvgIconType {
  bulb = "bulb",
  allReportIcon = "allReportIcon",
  communityIcon = "communityIcon",
  helpAndSupportIcon = "helpAndSupportIcon",
  peopleIcon = "peopleIcon",
  profileIcon = "profileIcon",
  settingIcon = "settingIcon",
  rocket = "rocket",
  taskIcon = "taskIcon",
  roundedPlusIcon = "roundedPlusIcon",
  rightDoubleArrowRounded = "rightDoubleArrowRounded",
  socialShare = "socialShare",
  viewIcon = "viewIcon",
  cupActiveIcon = "cupActiveIcon",
  cupIcon = "cupIcon",
  homeIcon = "homeIcon",
  homeActiveIcon = "homeActiveIcon",
  reportCenter = "reportCenter",
  reportCenterActive = "reportCenterActive",
  crossIcon = "crossIcon",
  crossIconWhite = "crossIconWhite",
  searchIcon = "searchIcon",
  downloadIcon = "downloadIcon",
  upDownFilter = "upDownFilter",
  userIcon = "userIcon",
  userActiveIcon = "userActiveIcon",
  pencilIcon = "pencilIcon",
  uploadIcon = "uploadIcon",
  communityIconNavBar = "communityIconNavBar",
  communityIconActiveNavBar = "communityIconActiveNavBar",
  rocketWithStars = "rocketWithStars",
  downIcon = "downIcon",
  upIcon = "upIcon",
  plusIcon = "plusIcon",
  minusIcon = "minusIcon",
  menuDotIcon = "menuDotIcon",
  myReportIcon = "myReportIcon",
  myReportIconActive = "myReportIconActive",
  peopleIconNavbar = "peopleIconNavbar",
  peopleIconActive = "peopleIconActive",
  downwardArrow = "downwardArrow",
  downwardArrowWhite = "downwardArrowWhite",
  logoutIcon = "logoutIcon",

  helpSupportNavbar = "helpSupportNavbar",

  teamIconGray = "teamIconGray",
  peopleIconGray = "peopleIconGray",
  negativeCircleBlue = "negativeCircleBlue",
  plusCircleBlue = "plusCircleBlue",
  zoomReset = "zoomReset",
  plusIconWhite = "plusIconWhite",
  nodeIcon = "nodeIcon",
  orgTeamIcon = "orgTeamIcon",
  orgTeamIconActive = "orgTeamIconActive",

  upwardArrow = "upwardArrow",
  documents = "documents",
  duplicate = "duplicate",
  deleteIcon = "deleteIcon",
  roundedFullPlusIcon = "roundedFullPlusIcon",
  upwardArrowWhite = "upwardArrowWhite",
  documentsIconWhite = "documentsIconWhite",
  duplicateIconWhite = "duplicateIconWhite",
  eyeSolid = "eyeSolid",
  eyeSlashSolid = "eyeSlashSolid",
  roundedBg = "roundedBg",
  playIcon = "playIcon",
  stackIcon = "stackIcon",
  podcastIcon = "podcastIcon",
  referIcon = "referIcon",
  specksIcon = "specksIcon",
  reportCenterIconOrange = "reportCenterIconOrange",
  sendIcon = "sendIcon",
  downloadIconWhite = "downloadIconWhite",
  downloadIcon2White = "downloadIcon2White",
  socialShareWhite = "socialShareWhite",
  feedBackIcon = "feedBackIcon",
  characterDialIcon = "characterDialIcon",
  characterDialIcon2 = "characterDialIcon2",
  environmentDialIcon = "environmentDialIcon",
  abilityDialIcon = "abilityDialIcon",
  upwardIndicator = "upwardIndicator",
  downwardIndicator = "downwardIndicator",
  abilityDashedLine = "abilityDashedLine",
  environmentDashedLine = "environmentDashedLine",
  environmentFlag = "environmentFlag",
  abilityFlag = "abilityFlag",
  inActiveFlag = "inActiveFlag",
  characterInactiveDialIcon = "characterInactiveDialIcon",
  environmentTipFlag = "environmentTipFlag",
  abilityTipFlag = "abilityTipFlag",
  greyIconUp = "greyIconUp",
  greyIconDown = "greyIconDown",
  abilityNavIcon = "abilityNavIcon",
  overviewNavIcon = "overviewNavIcon",
  characterNavIcon = "characterNavIcon",
  devPlanNavIcon = "devPlanNavIcon",
  environmentNavIcon = "environmentNavIcon",
  questionMarkIcon = "questionMarkIcon",
  blueUpIcon = "blueUpIcon",
  blueDownIcon = "blueDownIcon",
  clockIcon = "clockIcon",
  calenderIcon = "calenderIcon",
  downloadreportSvgIcon = "downloadreportSvgIcon",
  downloadReportSvgIconWhite = "downloadReportSvgIconWhite",
  shareReportSvgIcon = "shareReportSvgIcon",
  shareReportSvgIconDark = "shareReportSvgIconDark",
  aqLogoSvg = "aqLogoSvg",
  aiPredicitiveDashedLine = "aiPredicitiveDashedLine",
  warningIcon = "warningIcon",
  sparkleIconLight = "sparkleIconLight",
  constructIconLight = "constructIconLight",
  characterDashedLine = "characterDashedLine",
  smallAbilityActiveFlag = "smallAbilityActiveFlag",
  smallCharacterActiveFlag = "smallCharacterActiveFlag",
  smallInactiveFlag = "smallInactiveFlag",
  smallAqOptamizationFlag = "smallAqOptamizationFlag",
  genderGoldenIcon = "genderGoldenIcon",
  gitMergeGoldenIcon = "gitMergeGoldenIcon",
  infoCircleGoldenIcon = "infoCircleGoldenIcon",
  stopWatchGoldenIcon = "stopWatchGoldenIcon",
  bussinessGoldenIcon = "bussinessGoldenIcon",
  documentGolden = "documentGolden",
  briefcaseGoldenIcon = "briefcaseGoldenIcon",
  discGoldenIcon = "discGoldenIcon",
  calendarGoldenIcon = "calendarGoldenIcon",
  lowBandFlag = "lowBandFlag",
  midBandFlag = "midBandFlag",
  highBandFlag = "highBandFlag",
  inActiveBandFlag = "inActiveBandFlag",
  environmentSmallFlag = "environmentSmallFlag",
  userProfileIcon = "userProfileIcon",
  eyeIcon = "eyeIcon",
  eyeIconWithSlash = "eyeIconWithSlash",
  envleopeIcon = "envleopeIcon",
  sliderFilterIcon = "sliderFilterIcon",
  participantsRoundedLogo = "participantsRoundedLogo",
  googleLogo = "googleLogo",
  greenTickMark = "greenTickMark",
  redCrossIcon = "redCrossIcon",
  linkedInIcon = "linkedInIcon",
  mailIcon = "mailIcon",
  retryIcon = "retryIcon",
  deleteIconWhite = "deleteIconWhite",
  editingIcon = "editingIcon",
  bellIcon = "bellIcon",
  bellOffIcon = "bellOffIcon",
  settingsClassicIcon = "settingsClassicIcon",
  bellGreen = "bellGreen",
  circleHelp = "circleHelp",
  bellOffDarkIcon = "bellOffDarkIcon",
}

export const svgList: { [key in keyof typeof SvgIconType]: string } = {
  bulb,
  allReportIcon,
  communityIcon,
  helpAndSupportIcon,
  peopleIcon,
  profileIcon,
  settingIcon,
  rocket,
  taskIcon,
  roundedPlusIcon,
  rightDoubleArrowRounded,
  socialShare,
  viewIcon,
  cupActiveIcon,
  cupIcon,
  homeIcon,
  homeActiveIcon,
  reportCenter,
  reportCenterActive,
  crossIcon,
  crossIconWhite,
  searchIcon,
  downloadIcon,
  upDownFilter,
  userIcon,
  userActiveIcon,
  pencilIcon,
  uploadIcon,
  communityIconNavBar,
  communityIconActiveNavBar,
  rocketWithStars,
  downIcon,
  upIcon,
  plusIcon,
  minusIcon,
  menuDotIcon,
  myReportIcon,
  myReportIconActive,
  peopleIconNavbar,
  peopleIconActive,
  downwardArrow,
  logoutIcon,

  helpSupportNavbar,

  teamIconGray,
  peopleIconGray,
  negativeCircleBlue,
  plusCircleBlue,
  zoomReset,
  plusIconWhite,
  nodeIcon,
  orgTeamIcon,
  orgTeamIconActive,

  upwardArrow,
  documents,
  duplicate,
  deleteIcon,
  roundedFullPlusIcon,
  upwardArrowWhite,
  documentsIconWhite,
  duplicateIconWhite,
  eyeSolid,
  eyeSlashSolid,
  roundedBg,
  playIcon,
  stackIcon,
  podcastIcon,
  referIcon,
  specksIcon,
  downwardArrowWhite,
  reportCenterIconOrange,
  sendIcon,
  downloadIconWhite,
  downloadIcon2White,
  socialShareWhite,
  feedBackIcon,
  characterDialIcon,
  characterDialIcon2,
  abilityDialIcon,
  environmentDialIcon,
  upwardIndicator,
  downwardIndicator,
  abilityDashedLine,
  environmentDashedLine,
  environmentFlag,
  abilityFlag,
  inActiveFlag,
  characterInactiveDialIcon,
  abilityTipFlag,
  environmentTipFlag,
  greyIconUp,
  greyIconDown,
  abilityNavIcon,
  overviewNavIcon,
  characterNavIcon,
  devPlanNavIcon,
  environmentNavIcon,
  questionMarkIcon,
  blueUpIcon,
  blueDownIcon,
  clockIcon,
  calenderIcon,
  shareReportSvgIcon,
  downloadreportSvgIcon,
  aqLogoSvg,
  shareReportSvgIconDark,
  downloadReportSvgIconWhite,
  aiPredicitiveDashedLine,
  genderGoldenIcon,
  gitMergeGoldenIcon,
  infoCircleGoldenIcon,
  stopWatchGoldenIcon,
  bussinessGoldenIcon,
  documentGolden,
  briefcaseGoldenIcon,
  discGoldenIcon,
  calendarGoldenIcon,
  warningIcon,
  sparkleIconLight,
  constructIconLight,
  characterDashedLine,
  smallInactiveFlag,
  smallCharacterActiveFlag,
  smallAbilityActiveFlag,
  smallAqOptamizationFlag,
  lowBandFlag,
  midBandFlag,
  highBandFlag,
  inActiveBandFlag,
  environmentSmallFlag,
  userProfileIcon,
  eyeIcon,
  eyeIconWithSlash,
  envleopeIcon,
  sliderFilterIcon,
  participantsRoundedLogo,
  googleLogo,
  linkedInIcon,
  mailIcon,
  redCrossIcon,
  greenTickMark,
  retryIcon,
  deleteIconWhite,
  editingIcon,
  bellIcon,
  bellOffIcon,
  settingsClassicIcon,
  bellGreen,
  circleHelp,
  bellOffDarkIcon,
};

export default svgList;
