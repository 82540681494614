import { Languages } from "./languages.enum";

export enum IndustryType {
  AGRICULTURE = "agriculture_forestry_fishing",
  INDUSTRIALS_MANUFACTURING = "industrial_manufacturing_automotive_machinery_chemicals_and_materials_etc",
  CONSUMER_GOODS = "consumer_goods",
  CONSTRUCTION = "construction",
  MININGANDEXTRACTION = "mining_and_extraction",
  ENERGY = "energy_utilities",
  INDUSTRIALS_SERVICES = "industrial_services_transportation_logistics",
  MEDIA = "media_entertainment_creative_industries",
  IT = "information_technology_telecom",
  HEALTHCARE = "healthcare",
  EDUCATION = "education",
  LIFE_SCIENCES = "life_sciences",
  RETAIL = "retail_wholesale_ecommerce",
  HOSPITALITY = "hospitality_foodservice_leisurerecreation",
  PUBLIC_SERVICE = "Public Service, Social Service, Associations",
  FINANCIAL_SERVICE = "financial_services_insurance_real_estate",
  PROFESSIONAL_SERVICE = "professional_services_legal_management_consulting_information_etc",
  OTHER = "other",
}

export class IndustryTypeEnumUtils {
  private static getIndustryTypeTitleInEnglish(jobLevel: IndustryType): string {
    switch (jobLevel) {
      case IndustryType.AGRICULTURE: {
        return "Agriculture, Forestry, Mining";
      }

      case IndustryType.INDUSTRIALS_MANUFACTURING: {
        return "Industrial Manufacturing (Automotive, Machinery, Chemicals & Materials, etc.)";
      }
      case IndustryType.CONSUMER_GOODS: {
        return "Consumer Goods";
      }
      case IndustryType.CONSTRUCTION: {
        return "Construction";
      }
      case IndustryType.MININGANDEXTRACTION: {
        return "Mining & Extraction";
      }
      case IndustryType.ENERGY: {
        return "Energy, Utilities";
      }

      case IndustryType.INDUSTRIALS_SERVICES: {
        return "Industrial Services, Transportation, Logistics";
      }
      case IndustryType.MEDIA: {
        return "Media, Entertainment, Creative Industries";
      }
      case IndustryType.IT: {
        return "Information Technology, Telecom";
      }

      case IndustryType.HEALTHCARE: {
        return "Healthcare";
      }
      case IndustryType.EDUCATION: {
        return "Education";
      }
      case IndustryType.LIFE_SCIENCES: {
        return "Life Sciences";
      }
      case IndustryType.RETAIL: {
        return "Retail, Wholesale, Ecommerce";
      }
      case IndustryType.HOSPITALITY: {
        return "Hospitality, Foodservice, Leisure/Recreation";
      }
      case IndustryType.PUBLIC_SERVICE: {
        return "Public Service, Social Service, Associations";
      }
      case IndustryType.FINANCIAL_SERVICE: {
        return "Financial Services, Insurance, Real Estate";
      }
      case IndustryType.PROFESSIONAL_SERVICE: {
        return "Professional Services (Legal, Management, Consulting, Information, etc.)";
      }

      case IndustryType.OTHER: {
        return "Other";
      }
    }

    return "";
  }
  private static getIndustryTypeTitleInVietnamese(
    jobLevel: IndustryType,
  ): string {
    switch (jobLevel) {
      case IndustryType.AGRICULTURE: {
        return "Nông nghiệp, lâm nghiệp, khai thác";
      }

      case IndustryType.INDUSTRIALS_MANUFACTURING: {
        return "Sản xuất công nghiệp (ô tô, máy móc, hóa chất & vật liệu,...)";
      }
      case IndustryType.CONSUMER_GOODS: {
        return "Hàng tiêu dùng";
      }
      case IndustryType.CONSTRUCTION: {
        return "Xây dựng";
      }
      case IndustryType.MININGANDEXTRACTION: {
        return "Khai khoáng & Khai thác";
      }
      case IndustryType.ENERGY: {
        return "Năng lượng và tiện ích";
      }

      case IndustryType.INDUSTRIALS_SERVICES: {
        return "Dịch vụ công nghiệp, Vận tải, Vận chuyển";
      }
      case IndustryType.MEDIA: {
        return "Truyền thông, Giải trí, Công nghiệp Sáng tạo";
      }
      case IndustryType.IT: {
        return "Công nghệ thông tin, Viễn thông";
      }

      case IndustryType.HEALTHCARE: {
        return "Chăm sóc sức khỏe";
      }
      case IndustryType.EDUCATION: {
        return "Giáo dục";
      }
      case IndustryType.LIFE_SCIENCES: {
        return "Khoa học đời sống";
      }
      case IndustryType.RETAIL: {
        return "Bán lẻ, bán buôn, thương mại điện tử";
      }
      case IndustryType.HOSPITALITY: {
        return "Khách sạn, Ăn uống, Giải trí";
      }
      case IndustryType.PUBLIC_SERVICE: {
        return "Dịch vụ công cộng, Dịch vụ xã hội, Hiệp hội";
      }
      case IndustryType.FINANCIAL_SERVICE: {
        return "Dịch vụ tài chính, Bảo hiểm, Bất động sản";
      }
      case IndustryType.PROFESSIONAL_SERVICE: {
        return "Dịch vụ chuyên môn (Pháp lý, Quản lý, Tư vấn, Thông tin, v.v.)";
      }

      case IndustryType.OTHER: {
        return "Khác";
      }
    }

    return "";
  }

  private static getIndustryTypeTitleInTurkish(jobLevel: IndustryType): string {
    switch (jobLevel) {
      case IndustryType.AGRICULTURE: {
        return "Tarım, Ormancılık, Madencilik";
      }

      case IndustryType.INDUSTRIALS_MANUFACTURING: {
        return "Endüstriyel İmalat (Otomotiv, Makine, Kimyasallar ve Malzemeler vb.))";
      }
      case IndustryType.CONSUMER_GOODS: {
        return "tüketim malları";
      }
      case IndustryType.CONSTRUCTION: {
        return "İnşaat";
      }
      case IndustryType.MININGANDEXTRACTION: {
        return "Madencilik ve Çıkarma";
      }
      case IndustryType.ENERGY: {
        return "Enerji, Kamu Hizmetleri";
      }

      case IndustryType.INDUSTRIALS_SERVICES: {
        return "Endüstriyel Hizmetler, Taşımacılık, Lojistik";
      }
      case IndustryType.MEDIA: {
        return "Medya, Eğlence, Yaratıcı Endüstriler";
      }
      case IndustryType.IT: {
        return "Bilgi Teknolojisi, Telekom";
      }

      case IndustryType.HEALTHCARE: {
        return "Sağlık hizmeti";
      }
      case IndustryType.EDUCATION: {
        return "Eğitim";
      }
      case IndustryType.LIFE_SCIENCES: {
        return "Yaşam Bilimleri";
      }
      case IndustryType.RETAIL: {
        return "Perakende, Toptan Satış, E-ticaret";
      }
      case IndustryType.HOSPITALITY: {
        return "Ağırlama, Yemek servisi, Boş zaman/Dinlenme";
      }
      case IndustryType.PUBLIC_SERVICE: {
        return "Kamu Hizmeti, Sosyal Hizmet, Dernekler";
      }
      case IndustryType.FINANCIAL_SERVICE: {
        return "Finansal Hizmetler, Sigorta, Emlak";
      }
      case IndustryType.PROFESSIONAL_SERVICE: {
        return "Profesyonel Hizmetler (Hukuk, Yönetim, Danışmanlık, Bilgilendirme vb.)";
      }

      case IndustryType.OTHER: {
        return "Başka";
      }
    }

    return "";
  }

  private static getIndustryTypeTitleInSpanish(jobLevel: IndustryType): string {
    switch (jobLevel) {
      case IndustryType.AGRICULTURE: {
        return "Agricultura, Silvicultura, Pesca";
      }

      case IndustryType.INDUSTRIALS_MANUFACTURING: {
        return "Fabricación industrial (automoción, maquinaria, productos químicos y materiales, etc.)";
      }
      case IndustryType.CONSUMER_GOODS: {
        return "Bienes de consumo";
      }
      case IndustryType.CONSTRUCTION: {
        return "Construcción";
      }
      case IndustryType.MININGANDEXTRACTION: {
        return "Minería y Extracción";
      }
      case IndustryType.ENERGY: {
        return "Energía, Utilidades";
      }

      case IndustryType.INDUSTRIALS_SERVICES: {
        return "Servicios Industriales, Transporte, Logística";
      }
      case IndustryType.MEDIA: {
        return "Medios, entretenimiento, industrias creativas";
      }
      case IndustryType.IT: {
        return "Tecnología de la información, telecomunicaciones";
      }

      case IndustryType.HEALTHCARE: {
        return "Assistenza sanitaria";
      }
      case IndustryType.EDUCATION: {
        return "Educación";
      }
      case IndustryType.LIFE_SCIENCES: {
        return "Ciencias de la vida";
      }
      case IndustryType.RETAIL: {
        return "Comercio minorista, mayorista, comercio electrónico";
      }
      case IndustryType.HOSPITALITY: {
        return "Hostelería, restauración, ocio/recreación";
      }
      case IndustryType.PUBLIC_SERVICE: {
        return "Servizio Pubblico, Servizio Sociale, Associazioni";
      }
      case IndustryType.FINANCIAL_SERVICE: {
        return "Servicios Financieros, Seguros, Bienes Raíces";
      }
      case IndustryType.PROFESSIONAL_SERVICE: {
        return "Servicios Profesionales (Jurídicos, Gestión, Consultoría, Información, etc.)";
      }

      case IndustryType.OTHER: {
        return "Otro";
      }
    }

    return "";
  }

  private static getIndustryTypeTitleInFrench(jobLevel: IndustryType): string {
    switch (jobLevel) {
      case IndustryType.AGRICULTURE: {
        return "Agriculture, Sylviculture, Pêche";
      }

      case IndustryType.INDUSTRIALS_MANUFACTURING: {
        return "Fabrication industrielle (automobile, machines, produits chimiques et matériaux, etc.)";
      }
      case IndustryType.CONSUMER_GOODS: {
        return "Biens de consommation";
      }
      case IndustryType.CONSTRUCTION: {
        return "Costruzione";
      }
      case IndustryType.MININGANDEXTRACTION: {
        return "Exploitation minière et extraction";
      }
      case IndustryType.ENERGY: {
        return "Énergie, Services publics";
      }

      case IndustryType.INDUSTRIALS_SERVICES: {
        return "Services Industriels, Transport, Logistique";
      }
      case IndustryType.MEDIA: {
        return "Médias, divertissement, industries créatives";
      }
      case IndustryType.IT: {
        return "Technologie de l'information, Télécom";
      }

      case IndustryType.HEALTHCARE: {
        return "Soins de santé";
      }
      case IndustryType.EDUCATION: {
        return "Éducation";
      }
      case IndustryType.LIFE_SCIENCES: {
        return "Sciences de la vie";
      }
      case IndustryType.RETAIL: {
        return "Vente au détail, vente en gros, commerce électronique";
      }
      case IndustryType.HOSPITALITY: {
        return "Hôtellerie, restauration, loisirs/récréation";
      }
      case IndustryType.PUBLIC_SERVICE: {
        return "Fonction publique, Service social, Associations";
      }
      case IndustryType.FINANCIAL_SERVICE: {
        return "Services financiers, Assurance, Immobilier";
      }
      case IndustryType.PROFESSIONAL_SERVICE: {
        return "Services professionnels (juridique, gestion, conseil, information, etc.)";
      }
      case IndustryType.OTHER: {
        return "Autre";
      }
    }

    return "";
  }

  private static getIndustryTypeTitleInItalian(jobLevel: IndustryType): string {
    switch (jobLevel) {
      case IndustryType.AGRICULTURE: {
        return "Agricoltura, silvicoltura, pesca";
      }

      case IndustryType.INDUSTRIALS_MANUFACTURING: {
        return "Produzione industriale (auto, macchinari, prodotti chimici e materiali, ecc.)";
      }
      case IndustryType.CONSUMER_GOODS: {
        return "Beni di consumo";
      }
      case IndustryType.CONSTRUCTION: {
        return "Costruzione";
      }
      case IndustryType.MININGANDEXTRACTION: {
        return "Estrazione ed estrazione";
      }
      case IndustryType.ENERGY: {
        return "Énergie, Services publics";
      }

      case IndustryType.INDUSTRIALS_SERVICES: {
        return "Servizi Industriali, Trasporti, Logistica";
      }
      case IndustryType.MEDIA: {
        return "Media, intrattenimento, industrie creative";
      }
      case IndustryType.IT: {
        return "Informatica, Telecomunicazioni";
      }

      case IndustryType.HEALTHCARE: {
        return "Assistenza sanitaria";
      }
      case IndustryType.EDUCATION: {
        return "Formazione scolastica";
      }
      case IndustryType.LIFE_SCIENCES: {
        return "Scienze di vita";
      }
      case IndustryType.RETAIL: {
        return "Vendita al dettaglio, all'ingrosso, e-commerce";
      }
      case IndustryType.HOSPITALITY: {
        return "Ospitalità, Ristorazione, Tempo Libero/Ricreazione";
      }
      case IndustryType.PUBLIC_SERVICE: {
        return "Servizio Pubblico, Servizio Sociale, Associazioni";
      }
      case IndustryType.FINANCIAL_SERVICE: {
        return "Servizi finanziari, assicurazioni, immobiliare";
      }
      case IndustryType.PROFESSIONAL_SERVICE: {
        return "Servizi professionali (legali, gestionali, di consulenza, informazioni, ecc.)";
      }
      case IndustryType.OTHER: {
        return "Altro";
      }
    }

    return "";
  }

  private static getIndustryTypeTitleInGerman(
    industryType: IndustryType,
  ): string {
    switch (industryType) {
      case IndustryType.AGRICULTURE: {
        return "Landwirtschaft, Forstwirtschaft, Bergbau";
      }

      case IndustryType.INDUSTRIALS_MANUFACTURING: {
        return "Industrielle Fertigung (Automobilindustrie, Maschinenbau, Chemikalien und Materialien usw.)";
      }
      case IndustryType.CONSUMER_GOODS: {
        return "Konsumgüter";
      }
      case IndustryType.CONSTRUCTION: {
        return "Bauwesen";
      }
      case IndustryType.MININGANDEXTRACTION: {
        return "Bergbau und Gewinnung";
      }
      case IndustryType.ENERGY: {
        return "Energie, Versorgungsunternehmen";
      }

      case IndustryType.INDUSTRIALS_SERVICES: {
        return "Industriedienstleistungen, Transport, Logistik";
      }
      case IndustryType.MEDIA: {
        return "Medien, Unterhaltung, Kreativindustrie";
      }
      case IndustryType.IT: {
        return "Informationstechnologie, Telekommunikation";
      }

      case IndustryType.HEALTHCARE: {
        return "Gesundheitswesen";
      }
      case IndustryType.EDUCATION: {
        return "Bildung";
      }
      case IndustryType.LIFE_SCIENCES: {
        return "Biowissenschaften";
      }
      case IndustryType.RETAIL: {
        return "Einzelhandel, Großhandel, E -Commerce";
      }
      case IndustryType.HOSPITALITY: {
        return "Gastgewerbe, Gastronomie, Freizeit/Erholung";
      }
      case IndustryType.PUBLIC_SERVICE: {
        return "Öffentlicher Dienst, Sozialdienst, Verbände";
      }
      case IndustryType.FINANCIAL_SERVICE: {
        return "Finanzdienstleistungen, Versicherung, Immobilien";
      }
      case IndustryType.PROFESSIONAL_SERVICE: {
        return "Professionelle Dienstleistungen (Recht, Management, Beratung, Information usw.)";
      }

      case IndustryType.OTHER: {
        return "Andere";
      }
    }

    return "";
  }
  private static getIndustryTypeTitleInDutch(
    industryType: IndustryType,
  ): string {
    switch (industryType) {
      case IndustryType.AGRICULTURE: {
        return "Landbouw, Bosbouw, Mijnbouw";
      }
      case IndustryType.INDUSTRIALS_MANUFACTURING: {
        return "Industriële productie (automotive, machines, chemische stoffen en materialen, enz.)";
      }
      case IndustryType.CONSUMER_GOODS: {
        return "Consumentengoederen";
      }
      case IndustryType.CONSTRUCTION: {
        return "Bouw";
      }
      case IndustryType.MININGANDEXTRACTION: {
        return "Mijnbouw en extractie";
      }
      case IndustryType.ENERGY: {
        return "Energie, nutsvoorzieningen";
      }
      case IndustryType.INDUSTRIALS_SERVICES: {
        return "Industriële diensten, transport, logistiek";
      }
      case IndustryType.MEDIA: {
        return "Media, Entertainment, Creatieve Industrieën";
      }
      case IndustryType.IT: {
        return "Informatietechnologie, Telecommunicatie";
      }
      case IndustryType.HEALTHCARE: {
        return "Gezondheidszorg";
      }
      case IndustryType.EDUCATION: {
        return "Onderwijs";
      }
      case IndustryType.LIFE_SCIENCES: {
        return "Levenswetenschappen";
      }
      case IndustryType.RETAIL: {
        return "Detailhandel, Groothandel, E-commerce";
      }
      case IndustryType.HOSPITALITY: {
        return "Gastvrijheid, Horeca, Vrije tijd/Recreatie";
      }
      case IndustryType.PUBLIC_SERVICE: {
        return "Openbare dienstverlening, sociale dienstverlening, verenigingen";
      }
      case IndustryType.FINANCIAL_SERVICE: {
        return "Financiële diensten, verzekeringen, onroerend goed";
      }
      case IndustryType.PROFESSIONAL_SERVICE: {
        return "Professionele diensten (juridisch, management, consulting, informatie, enz.)";
      }
      case IndustryType.OTHER: {
        return "Ander";
      }
    }

    return "";
  }
  static getIndustryTypeTitle(
    industryType: IndustryType,
    locale?: Languages,
  ): string {
    switch (locale) {
      case Languages.ENGLISH:
        return this.getIndustryTypeTitleInEnglish(industryType);
      case Languages.SPANISH:
        return this.getIndustryTypeTitleInSpanish(industryType);
      case Languages.FRENCH:
        return this.getIndustryTypeTitleInFrench(industryType);
      case Languages.ITALIAN:
        return this.getIndustryTypeTitleInItalian(industryType);
      case Languages.TURKISH:
        return this.getIndustryTypeTitleInTurkish(industryType);
      case Languages.GERMAN:
        return this.getIndustryTypeTitleInGerman(industryType);
      case Languages.VIETNAMESE:
        return this.getIndustryTypeTitleInVietnamese(industryType);
      case Languages.DUTCH:
        return this.getIndustryTypeTitleInDutch(industryType);
      default:
        return this.getIndustryTypeTitleInEnglish(industryType);
    }
  }
}
