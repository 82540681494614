import { RoutePath } from "./models/RoutePath";

export class Routes {
  static forgotPassword: RoutePath = {
    path: "forgot-password",
    parent: null,
  };

  static updateProfile: RoutePath = {
    path: "update-profile",
    parent: null,
  };

  static login: RoutePath = {
    path: "login",
    parent: null,
  };

  static loginNew: RoutePath = {
    path: "login/new",
    parent: null,
  };

  static admin: RoutePath = {
    path: "admin",
    parent: null,
  };

  static dashboard: RoutePath = {
    path: "dashboard",
    parent: null,
  };

  static aqMeChatBot: RoutePath = {
    path: "aqme",
    parent: null,
  };

  static onBoardingTasks: RoutePath = {
    path: "onboarding/tasks",
    parent: null,
  };
  static onBoardingTasksDescription: RoutePath = {
    path: "onboarding/tasks/:id",
    parent: null,
  };

  static aqLiteChatBot: RoutePath = {
    path: "aqlite",
    parent: null,
  };

  static resetPassword: RoutePath = {
    path: "reset-password",
    parent: null,
  };

  static aqCredits: RoutePath = {
    path: "request/upgrade/:token",
    parent: null,
  };

  static paidInvite: RoutePath = {
    path: "request/upgrade/p/:token",
    parent: null,
  };

  static teamRegister: RoutePath = {
    path: "team/register/:token",
    parent: null,
  };

  static teamRegisterNew: RoutePath = {
    path: "new/team/register/:token",
    parent: null,
  };

  static register: RoutePath = {
    path: "register/:token",
    parent: null,
  };

  static affiliateKey: RoutePath = {
    path: ":affiliateKey",
    parent: null,
  };

  static individualSignup: RoutePath = {
    path: "aqme",
    parent: Routes.affiliateKey,
  };

  static aqLiteIndividualSignup: RoutePath = {
    path: "signup/aqlite",
    parent: null,
  };

  static teamSignup: RoutePath = {
    path: "aqteam",
    parent: Routes.affiliateKey,
  };

  static dimensions: RoutePath = {
    path: "dimensions",
    parent: Routes.admin,
  };

  static dimensionsHomePage: RoutePath = {
    path: "home",
    parent: Routes.dimensions,
  };
  static dimensionsAqmePage: RoutePath = {
    path: "aqme",
    parent: Routes.dimensions,
  };
  static dimensionsAqTeamPage: RoutePath = {
    path: "aqTeam",
    parent: Routes.dimensions,
  };
  static dimensionsAdvanceInsightsPage: RoutePath = {
    path: "advance-insights",
    parent: Routes.dimensions,
  };
  static dimensionsBadgesPage: RoutePath = {
    path: "badges",
    parent: Routes.dimensions,
  };

  static editDimensions: RoutePath = {
    path: ":id/edit",
    parent: Routes.dimensions,
  };

  static adminOrgnizations: RoutePath = {
    path: "organizations",
    parent: Routes.admin,
  };

  static adminUsers: RoutePath = {
    path: "users",
    parent: Routes.admin,
  };

  static adminDataExport: RoutePath = {
    path: "export",
    parent: Routes.admin,
  };

  static surveyAnswersExport: RoutePath = {
    path: "survey-answers",
    parent: Routes.adminDataExport,
  };

  static aqAndSubDimensionScore: RoutePath = {
    path: "aq-and-sub-dimension-scores",
    parent: Routes.adminDataExport,
  };

  static adminInvites: RoutePath = {
    path: "invites",
    parent: Routes.admin,
  };

  static adminReports: RoutePath = {
    path: "reports",
    parent: Routes.admin,
  };

  static adminDemographicReport: RoutePath = {
    path: "demographic",
    parent: Routes.adminReports,
  };

  static adminUtilizationReport: RoutePath = {
    path: "utilization",
    parent: Routes.adminReports,
  };

  static adminAqReportBuilderReport: RoutePath = {
    path: "aqReportBuilder",
    parent: Routes.adminReports,
  };

  static adminCommunity: RoutePath = {
    path: "community",
    parent: Routes.admin,
  };

  static adminCoupons: RoutePath = {
    path: "coupons",
    parent: Routes.admin,
  };

  static couponTransaction: RoutePath = {
    path: ":id/transaction",
    parent: Routes.adminCoupons,
  };

  static adminAffiliates: RoutePath = {
    path: "affiliates",
    parent: Routes.admin,
  };

  static promotionalImages: RoutePath = {
    path: "promotional-mages",
    parent: Routes.admin,
  };

  static adminMilestone: RoutePath = {
    path: "milestone",
    parent: Routes.admin,
  };

  static pushNotification: RoutePath = {
    path: "push-notification",
    parent: Routes.admin,
  };

  static adminAqJourney: RoutePath = {
    path: "aq-journey",
    parent: Routes.admin,
  };

  static adminTasks: RoutePath = {
    path: "tasks",
    parent: Routes.admin,
  };

  static affiliatesTransaction: RoutePath = {
    path: ":id/transaction",
    parent: Routes.adminAffiliates,
  };

  static partnerOrganization: RoutePath = {
    path: "partner-organizations",
    parent: Routes.admin,
  };

  static reportMetaInformation: RoutePath = {
    path: "report-metadata",
    parent: Routes.admin,
  };

  static viewRawData: RoutePath = {
    path: "report-rawdata",
    parent: Routes.admin,
  };

  static productUpdates: RoutePath = {
    path: "product-updates",
    parent: Routes.admin,
  };

  static editReportMetaInformation: RoutePath = {
    path: ":id/edit",
    parent: Routes.reportMetaInformation,
  };

  static accounts: RoutePath = {
    path: "accounts",
    parent: Routes.dashboard,
  };

  static aq_meForParticularUser: RoutePath = {
    path: ":user_id/:survey_id/aq-me",
    parent: Routes.dashboard,
  };

  static aq_me: RoutePath = {
    path: "aq-me",
    parent: Routes.dashboard,
  };

  static adminHome: RoutePath = {
    path: "home",
    parent: Routes.admin,
  };

  static adminAssessmentInvite: RoutePath = {
    path: "assessment-invite",
    parent: Routes.admin,
  };

  static home: RoutePath = {
    path: "home",
    parent: Routes.dashboard,
  };

  static my_journey: RoutePath = {
    path: "my-journey",
    parent: Routes.dashboard,
  };

  static profile_landing: RoutePath = {
    path: "profile",
    parent: Routes.dashboard,
  };
  static org_team_landing: RoutePath = {
    path: "organization-team-management",
    parent: Routes.dashboard,
  };

  static reports_center: RoutePath = {
    path: "reports-center",
    parent: Routes.dashboard,
  };
  static aqCoachReports: RoutePath = {
    path: "aqCoach",
    parent: Routes.reports_center,
  };
  static exportReports: RoutePath = {
    path: "export/aq-and-sub-dimension-scores",
    parent: Routes.reports_center,
  };

  static assessmentInviteAudit: RoutePath = {
    path: "assessment-invite-audit",
    parent: Routes.reports_center,
  };

  static aqMeReports: RoutePath = {
    path: "aqme",
    parent: Routes.reports_center,
  };
  static aqLiteReports: RoutePath = {
    path: "aqlite",
    parent: Routes.reports_center,
  };

  static aq_lite: RoutePath = {
    path: "aq-lite",
    parent: Routes.dashboard,
  };

  static aq_team: RoutePath = {
    path: "aq-team",
    parent: Routes.dashboard,
  };
  static aqTeamReportSummary: RoutePath = {
    path: "aq-team-summary",
    parent: Routes.reports_center,
  };

  static aq_meReport: RoutePath = {
    path: "report",
    parent: Routes.aq_me,
  };

  static aq_liteReport: RoutePath = {
    path: "report",
    parent: Routes.aq_lite,
  };

  static aq_liteReportAccToUserId: RoutePath = {
    path: "report/:id",
    parent: Routes.aq_lite,
  };

  static aq_teamReport: RoutePath = {
    path: "report",
    parent: Routes.aq_team,
  };

  static people: RoutePath = {
    path: "people",
    parent: Routes.dashboard,
  };

  static advancedInsights: RoutePath = {
    path: ":id/advanced-insights",
    parent: Routes.reports_center,
  };

  static aqmeReportAccToUserId: RoutePath = {
    path: ":user_id/:survey_id/aqme",
    parent: Routes.reports_center,
  };

  static aqmeReportOnePagerAccToUserId: RoutePath = {
    path: ":user_id/:survey_id/aqme-one-pager",
    parent: Routes.reports_center,
  };

  static teams: RoutePath = {
    path: "teams",
    parent: Routes.dashboard,
  };

  static community: RoutePath = {
    path: "community",
    parent: Routes.dashboard,
  };

  static education: RoutePath = {
    path: "education",
    parent: Routes.dashboard,
  };

  static platformContent: RoutePath = {
    path: "platform-content",
    parent: Routes.admin,
  };

  static adminReport: RoutePath = {
    path: "report",
    parent: Routes.admin,
  };
  static corporateReportPreview: RoutePath = {
    path: "report/preview",
    parent: Routes.admin,
  };
  static adminCorporateReportSummary: RoutePath = {
    path: "report/team-summary",
    parent: Routes.admin,
  };

  static adminAdvanceReportPreview: RoutePath = {
    path: "report/:id/advance-insights",
    parent: Routes.admin,
  };

  static cdpCorporateProfile: RoutePath = {
    path: "profile",
    parent: Routes.admin,
  };

  static cdpCorporateAccount: RoutePath = {
    path: "accounts",
    parent: Routes.admin,
  };

  static settings: RoutePath = {
    path: "settings",

    parent: Routes.admin,
  };
  static updatePassword: RoutePath = {
    path: "update-password",
    parent: Routes.admin,
  };

  static manageOTPs: RoutePath = {
    path: "manage-otp",

    parent: Routes.admin,
  };

  static aq_team_old: RoutePath = {
    path: "aq-team-old",
    parent: Routes.dashboard,
  };

  static aq_teamReport_old: RoutePath = {
    path: "report_old",
    parent: Routes.aq_team,
  };

  static notificationSettings: RoutePath = {
    path: "notification-settings",
    parent: Routes.dashboard,
  };

  static assessment: RoutePath = {
    path: "assessment",
    parent: null,
  };
}
