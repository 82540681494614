import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app/App";
import "./assets/css/tailwind-output.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION";

Sentry.init({
  dsn: "https://6f53121eb5beb8d527e802d5209f71c2@o4507763773997056.ingest.us.sentry.io/4508165318639616",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "https://app.aqai.io/",
    "https://api.aqai.io/",
    /^https:\/\/yourserver\.io\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: isProdEnvironment,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
